import {EnvelopeIcon, PhoneIcon} from '@heroicons/react/24/outline'
import {SelectField, TextArea, TextField} from "@/components/Fields";
import {Button} from "@/components/Button";
import {ContactForm} from "@/components/ContactForm";

export default function ContactInfo() {
    return (
        <div className="bg-white">
            <div className="mx-auto max-w-7xl py-16 px-6 lg:px-8">
                <div className="mx-auto max-w-lg md:grid md:max-w-none md:grid-cols-2 md:gap-8">
                    <div className="">
                        <div>
                            <h2 className="text-2xl font-bold text-gray-900 sm:text-3xl sm:tracking-tight font-display">
                                Adviseurs
                            </h2>
                            <div className="mt-3">
                                <p className="text-lg text-gray-500">
                                    Heb je nog geen contact gehad met onze adviseurs of ben je op zoek naar meer
                                    informatie?
                                </p>
                            </div>
                            <div className="mt-9">
                                <div className="flex">
                                    <div className="flex-shrink-0">
                                        <PhoneIcon className="h-6 w-6 text-gray-400" aria-hidden="true"/>
                                    </div>
                                    <div className="ml-3 text-base text-gray-500">
                                        <a href="tel:073 203 2572"
                                           className="text-gray-500 hover:text-gray-600">
                                            073 203 2572
                                        </a>
                                        <p className="mt-1">Ma-Vr van 09:00 tot 17:00</p>
                                    </div>
                                </div>
                                <div className="mt-6 flex">
                                    <div className="flex-shrink-0">
                                        <EnvelopeIcon className="h-6 w-6 text-gray-400" aria-hidden="true"/>
                                    </div>
                                    <div className="ml-3 text-base text-gray-500">
                                        <a href="mailto:support@bijlesplein.nl"
                                           className="text-gray-500 hover:text-gray-600">
                                            advies@bijlesplein.nl
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="mt-12 sm:mt-16 md:mt-8">
                            <h2 className="text-2xl font-bold text-gray-900 sm:text-3xl sm:tracking-tight font-display">Supportdesk</h2>
                            <div className="mt-3">
                                <p className="text-lg text-gray-500">
                                    Heb je een technische vraag over het platform? Ons team staat voor je klaar.
                                </p>
                            </div>
                            <div className="mt-9">
                                <div className="flex">
                                    <div className="flex-shrink-0">
                                        <PhoneIcon className="h-6 w-6 text-gray-400" aria-hidden="true"/>
                                    </div>
                                    <div className="ml-3 text-base text-gray-500">
                                        <a href="tel:073 203 2572"
                                           className="text-gray-500 hover:text-gray-600">
                                            073 203 2572
                                        </a>
                                        <p className="mt-1">Ma-Vr van 09:00 tot 17:00</p>
                                    </div>
                                </div>
                                <div className="mt-6 flex">
                                    <div className="flex-shrink-0">
                                        <EnvelopeIcon className="h-6 w-6 text-gray-400" aria-hidden="true"/>
                                    </div>
                                    <div className="ml-3 text-base text-gray-500">
                                        <a href="mailto:support@bijlesplein.nl"
                                           className="text-gray-500 hover:text-gray-600">
                                            support@bijlesplein.nl
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mt-12 sm:mt-16 md:mt-0">
                        <ContactForm/>
                    </div>
                </div>
            </div>
        </div>
    )
}
