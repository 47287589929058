import {TextArea, TextField} from "@/components/Fields";
import {Button} from "@/components/Button";
import {useForm} from "react-hook-form";
import axios from "axios";
import {useState} from "react";

export function ContactForm() {
    const [success, setSuccess] = useState(false)
    const [error, setError] = useState(null)
    const {register, handleSubmit, watch, formState: {errors}} = useForm();
    const onSubmit = data => {
        axios.post('/api/contact-form', data)
            .then(function (response) {
                setError(null)
                setSuccess(true)
            })
            .catch(function (error) {
                if (error?.response?.data?.message) {
                    setError(error.response.data.message)
                } else {
                    setError('Er ging iets mis bij het versturen van het formulier. Probeer het later opnieuw.')
                }
            });
    };

    return <div>
        <h2 className="text-2xl font-bold text-gray-900 sm:text-3xl sm:tracking-tight font-display">Stuur een
            bericht</h2>

        {error &&
            <div className="rounded-md bg-red-50 p-4 mt-4 -mb-5">
                <div className="flex">
                    <div className="flex-shrink-0">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                             strokeWidth="1.5" stroke="currentColor" className="h-5 w-5 text-red-400">
                            <path strokeLinecap="round" strokeLinejoin="round"
                                  d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"/>
                        </svg>

                    </div>
                    <div className="ml-3">
                        <h3 className="text-sm font-medium text-red-800">{error}</h3>
                    </div>
                </div>
            </div>
        }

        {success ?
            <div className="rounded-md bg-green-50 p-4 mt-4">
                <div className="flex">
                    <div className="flex-shrink-0">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                             strokeWidth="1.5" stroke="currentColor" className="h-5 w-5 text-green-400">
                            <path strokeLinecap="round" strokeLinejoin="round"
                                  d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"/>
                        </svg>

                    </div>
                    <div className="ml-3">
                        <h3 className="text-sm font-medium text-green-800">Bericht verstuurd</h3>
                        <div className="mt-2 text-sm text-green-700">
                            <p>Bedankt voor je bericht. We komen hier zo snel mogelijk op terug.</p>
                        </div>
                    </div>
                </div>
            </div>
            :
        <form
            onSubmit={handleSubmit(onSubmit)}
            className="mt-10 grid grid-cols-1 gap-y-8 gap-x-6 sm:grid-cols-2"
        >
            <TextField
                label="Voornaam"
                id="first_name"
                name="first_name"
                type="text"
                autoComplete="given-name"
                required
                register={register("first_name", {required: true})}
            />
            <TextField
                label="Achternaam"
                id="last_name"
                name="last_name"
                type="text"
                autoComplete="family-name"
                required
                register={register("last_name", {required: true})}
            />
            <TextField
                className="col-span-full"
                label="Email"
                id="email"
                name="email"
                type="email"
                autoComplete="email"
                required
                register={register("email", {required: true})}
            />
            <TextArea
                className="col-span-full"
                label="Bericht"
                id="message"
                name="message"
                required
                register={register("message", {required: true})}
            />
            <div className="col-span-full">
                <Button
                    type="submit"
                    variant="solid"
                    color="blue"
                    className="w-full"
                >
              <span>
                Versturen <span aria-hidden="true">&rarr;</span>
              </span>
                </Button>
            </div>
        </form> }
    </div>
}
